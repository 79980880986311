// personal
import './index.less';
import React ,{useState,useEffect,useImperativeHandle} from 'react'
import { useLocation,useHistory } from 'react-router-dom';
import api from '@/api'
import {useUserInfo} from '@/hooks/user.js'
import Avatar from '@mui/material/Avatar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconSchool from '@/assets/images/user/school.svg'
import IconPets from '@/assets/images/user/pets.svg'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import {mainType} from '@/utils/district.js'
import {addTime} from '@/utils/shifttime.js'
const drawerBleeding = 0;
import ProhibitBack from '@/components/ProhibitBack'
const Personal = (props,ref)=>{
  const history = useHistory()
  const {acquire} = useUserInfo()
  const dispatch = useDispatch()
  const [open,setOpen] = useState(false)
  const [detail,setDetail] = useState()
  // 社区
  const [community,setCommunity] = useState()
  // 证书
  const [certList,setCertList] = useState([])
  const [info,setInfo] = useState(Object)
  const [userShare,setUserShare] = useState([])
  const [eventJoin,setEventJoin] = useState([])
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      if(!e.uid)return
      setOpen(true)
      handleInfoView(e.uid)
    }
  }))
  const handleInfoView = async(uid)=>{
    const {data,code,msg} = await acquire(uid)
    var type = mainType.filter(item=>item.value == data.user_type)[0].type
    setCommunity(type)
    setInfo(data)
    var imgObj = [];
    for(var i in data.cert_list){
      imgObj.push(api.url + data.cert_list[i].cert_img)
    }
    setCertList(imgObj)
    handleUserShare(uid)
    handleUserEventJoinList(uid)
  }
  const handleClose = (newOpen)=>{
    setOpen(newOpen)
    if(!props.close)return 
    props?.close()
  }
  // 获取分享
  const handleUserShare = async(uid)=>{
    const {data,code,msg} = await api.user.GetUserEvent({
      friend_uid:uid
    })
    if(data.length == undefined)return setUserShare([])
    setUserShare(data)
  }
  const handleUserEventJoinList = async(uid)=>{
    const {data,code,msg} = await api.user.GetUserEventJoinList({
      friend_uid:uid
    })
    if(data.length == undefined)return setEventJoin([])
    setEventJoin(data)
  }
  // 预览
  const handlePreview = (index)=>{
    if(window.plus){
      window.plus.nativeUI.previewImage([...certList],{
        current:index,
        loop:true,
        onLongPress:function(e){
          outLine('onLongPress: '+JSON.stringify(e));
          showActionSheet();
        }
      });
    }
  }
  const handlePush = (e)=>{
    handleClose(false)
    setTimeout(()=>{
      if(e.type == 'event' || e.is_share != undefined){
        history.push(`/sharing/details/?id=${e.event_id || e.id}`)
      }else if(e.type == 'product'|| e.new_product_type){
        history.push(`/shopping/productDetail/?id=${e.id || e.product_id}`)
      }else{
        history.push(`/shopping/vegetable/?id=${e.event_id || e.id}`)
      }
    },10)
    
  }
  return (
    <>
      <ProhibitBack open={open} close={()=>handleClose(false)} />
      <SwipeableDrawer
        className="personal-dialog"
        // container={container}
        anchor="bottom"
        open={open}
        onClose={()=>handleClose(false)}
        onOpen={()=>handleClose(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="personal-main">
          <div className="personal-main_top">
          </div>
          <div className="personal-main_card">
            <div className="card-left">
              <Avatar src={api.url + info?.image} />
              <div className="card-name">
                {info?.username}
              </div>
            </div>
            <div className="card-right">
              <div className="card-list">
                <div className="card-list_item">
                  <div className="item-type">
                    {community}會員
                  </div>
                </div>
                <div className="card-list_item">
                  <div className="item-text">
                    {certList.length || 0}個
                  </div>
                  <div className="item-des">
                    已獲機構親自認證的資歷
                  </div>
                </div>
                <div className="card-list_item">
                  <div className="item-text">
                    {addTime(info?.create_time)}
                  </div>
                  <div className="item-des">
                    加入時間
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-main_info">
            <div className="info-item">
              <div className="info-item_label">
                <img className="info-item_img" src={IconSchool} alt="" />
                <div className="info-item_text">
                  {/* Studied in abc where-ever school */}
                  {info?.edu_level}
                </div>
              </div>
            </div>
            {/* <div className="info-item">
              <div className="info-item_label">
                <img className="info-item_img" src={IconDistance} alt="" />
                <div className="info-item_text">
                  {info?.address}
                </div>
              </div>
            </div> */}
            <div className="info-item">
              <div className="info-item_label">
                <img className="info-item_img" src={IconPets} alt="" />
                <div className="info-item_text">
                  {info?.personal_skills}
                </div>
              </div>
            </div>
          </div>
          <div className="personal-main_intro">
            {info?.introduction}
          </div>
          <div className="personal-mian_read">
            閱讀更多
          </div>
          <div className="personal-mian_title">
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.5 3.7366e-06C9.32441 3.7366e-06 7.19767 0.645142 5.38873 1.85384C3.57979 3.06253 2.16989 4.7805 1.33733 6.79048C0.504766 8.80047 0.28693 11.0122 0.711367 13.146C1.1358 15.2798 2.18345 17.2398 3.72183 18.7782C5.26021 20.3165 7.22022 21.3642 9.35401 21.7886C11.4878 22.2131 13.6995 21.9952 15.7095 21.1627C17.7195 20.3301 19.4375 18.9202 20.6462 17.1113C21.8549 15.3023 22.5 13.1756 22.5 11C22.5012 9.55512 22.2175 8.12419 21.6651 6.78907C21.1127 5.45396 20.3025 4.24085 19.2808 3.21917C18.2591 2.19749 17.046 1.38728 15.7109 0.834902C14.3758 0.282523 12.9449 -0.00118763 11.5 3.7366e-06ZM11.5 19.4118C9.83631 19.4118 8.20998 18.9184 6.82668 17.9941C5.44337 17.0698 4.36521 15.7561 3.72855 14.219C3.09188 12.682 2.9253 10.9907 3.24987 9.35895C3.57444 7.72722 4.37558 6.22839 5.55199 5.05199C6.72839 3.87558 8.22723 3.07444 9.85895 2.74987C11.4907 2.4253 13.182 2.59188 14.719 3.22855C16.2561 3.86521 17.5698 4.94337 18.4941 6.32668C19.4184 7.70998 19.9118 9.33631 19.9118 11C19.914 12.1053 19.6979 13.2001 19.276 14.2217C18.854 15.2432 18.2345 16.1714 17.453 16.953C16.6714 17.7345 15.7432 18.354 14.7217 18.776C13.7001 19.1979 12.6053 19.414 11.5 19.4118ZM14.7353 7.57059L10.9824 12.1L8.39412 10.2235C7.81177 9.77059 7.0353 9.9 6.58236 10.4824C6.12941 11.0647 6.25883 11.8412 6.84118 12.2941C7.61765 12.8765 8.45883 13.4588 9.2353 14.0412C9.81765 14.4294 10.3353 15.0118 11.0471 15.0765C11.2412 15.0765 11.5 15.0765 11.6941 14.9471L12.0824 14.5588L14.9941 11.0647C15.5118 10.4176 16.0294 9.83529 16.5471 9.18824C16.9353 8.67059 17.0647 8.02353 16.6118 7.50588L16.4176 7.31177C15.9647 6.98824 15.1882 7.05294 14.7353 7.57059Z" fill="black"/>
            </svg>
            <span>認證的資歷：</span>
          </div>
          <div className="personal-mian_img">
            {certList.map((item,index)=><img key={index} src={item} alt="" onClick={()=>handlePreview(index)} />)}
          </div>
          <div className="personal-mian_title">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.82439 10.4872L9.42531 18.6237C9.72754 20.1599 11.7915 20.4656 12.5261 19.083L20.3033 4.44357C20.6042 3.87721 20.5414 3.25126 20.2405 2.77469M7.82439 10.4872L2.00974 4.85827C0.935713 3.81854 1.67174 2 3.16659 2H18.8345C19.4476 2 19.9528 2.31908 20.2405 2.77469M7.82439 10.4872L20.2405 2.77469M20.2405 2.77469L20.3489 2.70727" stroke="black" stroke-width="2.5"/>
            </svg>
            <span>最近的分享：</span>
          </div>
          <div className="personal-mian_img">
            {userShare.map((item,index)=><img key={index} src={api.url + item.banner} alt="" onClick={()=>handlePush(item)} />)}
          </div>
          <div className="personal-mian_title">
            <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.72181 7.63299C0.426065 11.9287 0.426065 18.8936 4.72181 23.1894C9.01756 27.4851 15.9824 27.4851 20.2782 23.1894C24.5739 18.8936 24.5739 11.9287 20.2782 7.63299C18.1289 5.48372 15.3115 4.40978 12.4945 4.41116L10.0556 4.41113" stroke="#292929" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.5 1.9668L10.0556 4.41124L12.5 6.85569" stroke="#292929" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.1787 11.8125V16.5658H17.932" stroke="#292929" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>最近的參與：</span>
          </div>
          <div className="personal-mian_img">
            {eventJoin.map((item,index)=><img key={index} src={api.url + item.banner} alt="" onClick={()=>handlePush(item)} />)}
          </div>
          <div className="personal-main_btn">
            {props.children}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  )
}

export default Personal