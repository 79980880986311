function screenHome(formData){
  return {
    type:"SCREEN",
    value:formData
  }
}
function clearHome(){
  return {
    type:"CLEAR",
  }
}
export {
  screenHome,
  clearHome
}