import './index.less'
import React ,{useState,useImperativeHandle, useEffect} from 'react'
import {useApplicant} from '@/hooks/sharing.js'
import {useParameter} from '@/hooks/useParameter.js'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {useHistory,Prompt} from 'react-router-dom'
const Tips =(props,ref) =>{
  const history = useHistory()
  const [accreditOpen,setAccreditOpen] = useState(false)
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setAccreditOpen(true)
    }
  }))
  const handleAccreditClose = ()=>{
    
  }
  const handleSubmit = ()=>{
    props.setTradingBool(false)
    history.go(-1)
  }
  return (
    <>
    <div className="initiate-for_dialog">
      <Dialog
        className="initiate-for_dialog"
        open={accreditOpen}
        onClose={handleAccreditClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >                                                                       
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            返回頁面交易流程也將繼續，審核通過自動扣款。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  )
}
export default Tips
