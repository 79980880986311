export default function reducer(prevState ={
  list:[],
  count:0,
  // 购买弹框
  bool:false,
  // 购买信息
  buy:{
    // 购买id
    buyid:'',
    // 购买地址
    address:'',
  }
},action){
  var newStete = {...prevState}
  switch(action.type){
    case "SHOP" :
      newStete.list = action.value
      return newStete
    case "COUNT" :
      newStete.count = action.value
      return newStete
    case "BUY" :
      newStete.buy = action.value
      return newStete
    case "BOOL" :
      console.log(action.value)
      newStete.bool = action.value
      return newStete
    default:
      return newStete
  }
}