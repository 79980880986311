import './index.less'
import React ,{useState,useImperativeHandle, useEffect} from 'react'
import {useApplicant} from '@/hooks/sharing.js'
import {useParameter} from '@/hooks/useParameter.js'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {getInitiate,setInitiate} from '@/utils/auth.js'
import { useSelector } from 'react-redux';
const Initiate =(props,ref) =>{
  const {singup} = useApplicant()
  const paramter = useParameter()
  const [accreditOpen,setAccreditOpen] = useState(false)
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    if(!redusxUser?.user_type)return
    console.log(getInitiate())
    if(!getInitiate()){
      setAccreditOpen(true)
    }
  },[redusxUser])
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setAccreditOpen(true)
    }
  }))
  const handleAccreditClose = ()=>{
    
  }
  const handleSubmit = ()=>{
    setInitiate(true)
    setAccreditOpen(false)
  }
  return (
    <>
    <div className="initiate-for_dialog">
      <Dialog
        className="initiate-for_dialog"
        open={accreditOpen}
        onClose={handleAccreditClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >                                                                       
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            時分區區通 應用程式 承蒙香港交易所慈善基金資助，香港公益金撥款及監察，由 「關注草根生活聯盟」、「香港婦女中心協會」及「香港聖公會麥理浩夫人中心 」共同營運。透過攜手建立 「跨區電子時分交易平台」，促進各區及跨區時分的使用及流通。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            我已知曉
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  )
}
export default Initiate
