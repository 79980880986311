// marketplace -- 
import React,{useState,useEffect,useCallback,useMemo,memo,useRef,forwardRef} from 'react'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import { useLocalList,useAllList,useConsumption } from '@/hooks/home.js'
import {useNavigation} from '@/hooks/useNavigation.js'
import BScroll from 'better-scroll'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Location_TimeExLogo_Both1 from '@/assets/images/home/Location_TimeExLogo_Both1.svg'
import Location_TimeExLogo_Both2 from '@/assets/images/home/Location_TimeExLogo_Both2.svg'
import Location_TimeExLogo_Both3 from '@/assets/images/home/Location_TimeExLogo_Both3.svg'
import Location_TimeExLogo_Both from '@/assets/images/home/Location_TimeExLogo_Both.svg'
import Location from '@/assets/images/home/Location.svg'
import Wc from '@/assets/images/home/WC.svg'
import Wc2 from '@/assets/images/home/WC2.svg'
import Wc3 from '@/assets/images/home/WC3.svg'
import OrangeCH from '@/assets/images/login/OrangeCH.svg'
import group23 from '@/assets/images/login/group23.svg'
import group21 from '@/assets/images/login/group21.svg'
import IconLogout from '@/assets/images/home/logout.svg'
import './index.less'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux'
import api from '@/api'
import {logoutAction,UserClearAction,UserAction,screenHome,clearHome} from '@/redux/actions'
import CardModule from './Card'
import DataList from './DataList'
import Assist from '@/components/Assist'
import Screen from './Screen'
function Home(){
  useNavigation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const paramter = useParameter()
  const history = useCustomHistory()
  const [value,setValue] = useState('local')
  // const [allType,setAllType] = useState(paramter.value || 'institution')
  const {consumption} = useConsumption()
  const assistRef = useRef(null)
  const AssistDiv = forwardRef(Assist)
  const ScreenRef = useRef(null)
  const ScreenDiv = forwardRef(Screen)
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const redusxBalance = useSelector((state)=>{
    return state.UserRd.balance
  })
  useEffect(()=>{
    setValue(paramter.type || 'all')
  },[paramter.type])
  useEffect(()=>{
    return(()=>{

    })
  })
  const handleChange = useCallback((event, newValue) => {
    history.replace(
      `/home/?type=${newValue}`,
    )
    dispatch(clearHome())
  },[]);
  const handleContactDetails = useCallback((e)=>{
    assistRef.current.handleOpen()
  },[])
  const handleAllType = useCallback(()=>{
    ScreenRef.current.handleOpen()
  },[])
  const handleLogout = useCallback(()=>{
    history.replace('/entrance/login')
    dispatch(logoutAction())
    dispatch(UserClearAction())
  },[])
  return (
    <div className='marketplace'>
      {/* <Tourist /> */}
      <ScreenDiv ref={ScreenRef} type={value} />
      <div className='marketplace-head'>
        <div className='marketplace-head_top'>
          <img className='timeExLogo' src={redusxUser.user_type == 1 && Location_TimeExLogo_Both1 || redusxUser.user_type == 2 && Location_TimeExLogo_Both2 || redusxUser.user_type == 3 && Location_TimeExLogo_Both3 || !redusxUser.user_type && Location_TimeExLogo_Both} alt="" />
          <div className="tourist-head_right">
            {redusxUser?.uid&&<div className="logout">
              <div className="logout-img"><img src={redusxUser.user_type == 1 && Wc || redusxUser.user_type == 2 && Wc2 || redusxUser.user_type == 3 && Wc3} alt="" onClick={()=>handleContactDetails(true)}/>
              </div>
              <span>小幫手</span>
            </div>}
            {!redusxUser?.uid && <div className="logout">
              <div className="logout-img"><img src={IconLogout} alt="" onClick={()=>handleLogout()} /></div>
              <span>登入</span>
            </div>}
          </div>
        </div>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="本區市集" value="local"/>
          <Tab label="區區通市集" value="all"/>
        </Tabs>
        <div className='marketplace-head_status'>
          <span>
            尚餘{value == 'all' ? '區區通' : 
              redusxUser.user_type == '1' && '婦女中心'||
              redusxUser.user_type == '2' && '關草'||
              redusxUser.user_type == '3' && '麥理浩'
            }
            時分：
            {value != 'all' && redusxBalance}
            {value == 'all'&&  consumption?.tran_amount_limit - consumption?.total_money+
            '/'+consumption?.tran_amount_limit}
          </span>
          <svg onClick={()=>handleAllType()} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.60718 3.93945H24" stroke="#CE4C09" stroke-width="2" stroke-linecap="round"/>
            <path d="M1.60718 11.5527H24" stroke="#CE4C09" stroke-width="2" stroke-linecap="round"/>
            <path d="M1.60718 20.0615H24" stroke="#CE4C09" stroke-width="2" stroke-linecap="round"/>
            <path d="M5.86182 1.25195L5.86182 6.62622" stroke="#CE4C09" stroke-width="2" stroke-linecap="round"/>
            <path d="M20.1931 8.86523L20.1931 14.2395" stroke="#CE4C09" stroke-width="2" stroke-linecap="round"/>
            <path d="M13.0276 17.375L13.0276 22.7493" stroke="#CE4C09" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
      <div className='marketplace-main'>
        {value == 'local' && <User/>}
        {value == 'all' && <All />}
      </div>
      <AssistDiv ref={assistRef} />
    </div>
  )
}
export default React.memo(Home)
function Tourist(){
  return (
    <div className='tourist'>
      {/* <div className='tourist-head'>
        <img className='timeExLogo' src={Location_TimeExLogo_Both} alt="" />
        <div className="tourist-head_right">
          <img src={Location_Character} alt="" />
        </div>
      </div> */}
      <div className="tourist-city">
        <img src={Location} alt="" />
        <span className="tourist-city_text">
          我係長沙灣區
        </span>
      </div>
      <div className="card-list">
        {[1,2,3,4,5].map((item)=><CardModule key={item}/>)}
      </div>
      <div className="tourist-city">
        <img src={Location} alt="" />
        <span className="tourist-city_text">
          我係長沙灣區
        </span>
      </div>
      <div className="card-list">
        {[1,2,3,4,5].map((item)=><CardModule key={item}/>)}
      </div>
    </div>
  )
}

const User = memo(({})=>{
  const formData = useSelector((state)=>{
    return state.HomeRd.formData
  })
  const { t } = useTranslation()
  const { organization,goods ,shopping , event , request , userEvent} = useLocalList(formData)
  useEffect(()=>{
  },[])
  return (
    <>
      <div className='user tourist'>
        {(organization.length>0 && (formData.publisher == 'institution' || formData.publisher == '')) && <><div className="tourist-city">
            <span className="tourist-city_text">
              機構產品
            </span>
          </div>
          <DataList data={organization} />
        </>}
        {(goods.length>0 && (formData.publisher != 'institution' || formData.publisher == '')) && <><div className="tourist-city">
            <span className="tourist-city_text">
              物品交易
            </span>
          </div>
          <DataList data={goods} />
        </>}
        {(shopping.length>0 && (formData.publisher == 'institution' || formData.publisher == '')) && <><div className="tourist-city">
            <span className="tourist-city_text">
              購物日
            </span>
          </div>
          <DataList data={shopping} />
        </>}
        {(event?.length>0 && (formData.publisher == 'institution' || formData.publisher == '')) && <><div className="tourist-city">
          <span className="tourist-city_text">
            {t('home.event')}
          </span>
        </div>
        <DataList data={event} />
        </>}
        {(request?.length > 0  && (formData.publisher != 'institution' || formData.publisher == '')) && <><div className="tourist-city">
          <span className="tourist-city_text">
            服務請求
          </span>
        </div>
        <div className="card-list">
          <DataList data={request} />
        </div></>}
        {(userEvent?.length>0 && (formData.publisher != 'institution' || formData.publisher == '')) && <><div className="tourist-city">
          <span className="tourist-city_text">
            用戶發佈的活動
          </span>
        </div>
        <DataList data={userEvent} />
        </>}
        
      </div>
      
    </>
  )
})
const All = memo(()=>{
  const formData = useSelector((state)=>{
    return state.HomeRd.formData
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const {community1,community2,community3} = useAllList(formData);
  return (
    <div className="home-all">
      {community1?.length > 0 && <>
        <div className={`home-all_main ${redusxUser.user_type == 1 ? 'home-all_mian-c' : ''}`} >
          <div className="home-all_title">
            <div className="home-all_title-img">
              <img src={OrangeCH} />
            </div>
            <span>香港婦女中心協會</span>
          </div>
          <div className="card-list community-a">
            <DataList data={community1} />
          </div>
        </div>
      </>}
      {community3?.length > 0 && <>
        <div className={`home-all_main ${redusxUser.user_type == 3 ? 'home-all_mian-c' : ''}`}>
          <div className="home-all_title">
            <div className="home-all_title-img">
              <img src={group21} />
            </div>
            <span>香港聖公會麥理浩夫人中心</span>
          </div>
          <div className="card-list community-b">
            <DataList data={community3} />
          </div>
        </div>
      </>}
      {community2.length > 0 && <>
        <div className={`home-all_main ${redusxUser.user_type == 2 ? 'home-all_mian-c' : ''}`} >
          <div className="home-all_title">
            <div className="home-all_title-img">
              <img src={group23} />
            </div>
            <span>關注草根生活聯盟</span>
          </div>
          <div className="card-list community-c">
            <DataList data={community2} />
          </div>
        </div>
      </>}
      {/* {product?.data?.length > 0 &&<div className="home-all_main">
        <div className="home-all_title">
          <span>产品</span>
        </div>
        <div className="card-list">
          <DataList data={product.data} />
        </div>
      </div>} */}
      {/* {request?.data.length > 0 && <><div className="home-all_title">
        <span>服務請求</span>
      </div>
      <div className="card-list">
        <DataList data={request.data} />
      </div></>} */}
    </div>
  )
})
