import './index.less'
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
export default function WeightInput({label,value,name,max,formData,setFormData,dispatch,change}){
  const handleChange = (event)=>{
    const newValue = event.target.value;
    const newName = event.target.name
    if(newValue > max){
      setFormData({ ...formData, [newName]: max })
      if(change) change(max)
      return
    }
    if(!newValue){
      setFormData({ ...formData, [newName]: 1 })
      if(change) change(1)
      return
    }
    if (newValue === '' || /^[0-9]+$/.test(newValue)) {
      setFormData({ ...formData, [newName]: newValue })
      if(change) change(newValue)
    }
    
  }
  return (
    <FormControl className="WeightInput">
      <Input
        id="standard-adornment-weight"
        endAdornment={<InputAdornment position="end">{label}</InputAdornment>}
        aria-describedby="standard-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        name={name}
        min={1}
        max={max}
        type="number"
        value={value}
        defaultValue={value}
        dispatch={dispatch}
        onChange={(event)=>handleChange(event)}
      />
    </FormControl>
  )
}