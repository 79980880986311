import './index.less'
import {useState,useEffect,useRef,useImperativeHandle,forwardRef} from 'react'
import {useHistory,Prompt} from 'react-router-dom'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import {tipsReveal} from '@/redux/actions'
import { useDispatch,useSelector } from 'react-redux';
import Back from '@/assets/images/shop/back.svg'
import subtract from '@/assets/images/shop/subtract.svg'
import add from '@/assets/images/shop/add.svg'
import reserve from '@/assets/images/shop/reserve.svg'
import scene from '@/assets/images/shop/scene.svg'
import {ShopAction,BalanceAction,ShopBool,ShopBuyId} from '@/redux/actions'
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconClose from '@/assets/images/shop/close.svg'
import api from '@/api'
import {useNavigation} from '@/hooks/useNavigation.js'
import Precise from '@/utils/precise'
// import OrderDialog from './components/OrderDialog'
import WeightInput from '@/components/WeightInput'
import ProhibitBack from '@/components/ProhibitBack'
import Tips from './components/Tips'
export default function Shear(){
  useNavigation()
  const dispatch = useDispatch()
  const history = useHistory()
  const paramter = useParameter()
  const [money,setMoney] = useState(230)
  const [list,setList] = useState([])
  const [eventId,setEventId] = useState('')
  const [addressValue,setAddressValue] = useState('')
  const [addressList,setAddressList] = useState([])
  const [TradingBool,setTradingBool] = useState(false)
  // const OrderDialogRef = useRef(null)
  // const OrderDialogDiv = forwardRef(OrderDialog)
  const TipsRef = useRef(null)
  const TipsDiv = forwardRef(Tips)
  const redusxBalance = useSelector((state)=>{
    return state.UserRd.balance
  })
  const redusxBuy = useSelector((state)=>{
    return state.ShoppingCartRd.buy
  })
  useEffect(()=>{
    if(redusxBuy.buyid){
      setTradingBool(true)
    }else{
      setTradingBool(false)
    }
  },[redusxBuy])
  // const handleBeforeUnload = ()=>{
  //   console.log(12345)
  // }
  useEffect(()=>{
    handleList()
  },[paramter.buyid])
  useEffect(()=>{
    if(paramter.type == 'scene')return
    handleDetails()
  },[list])
  useEffect(()=>{
    setMoney(redusxBalance)
  },[redusxBalance])
  const handleList = async()=>{
    const {data,code,msg} = await api.shop.GetCartBuyDetail({
      buy_id:paramter.buyid
    })
    setList([data])
    console.log(data)
  }
  const handleDetails = async()=>{
    var data;
    if(!list[0]?.event_id && !list[0]?.product_id)return
    if(!list[0]?.event_id){
      data = await api.home.GetCommonProductDetail({
        product_id:list[0]?.product_id
      })
    }else{
      data = await api.home.GetCommonEventDetail({
        event_id:list[0]?.event_id
      })
    }
    setAddressList(JSON.parse(data.data.pick_up_address))
  }
  const handleBack = ()=>{
    history.go(-1)
  }
  const handleUpload = async (id,updatedItem)=>{
    const {dat,code,msg} = await api.shop.PutCart({
      product_id:id,
      ...updatedItem,
    })
    if(code != 0)return 
    setList(list.map(item => (item.product_id === id ? { ...item, ...updatedItem } : item)));
  }
  // 支付
  const handleSubmit = async ()=>{
    if(paramter.type == 'reserve'){
      if(!addressValue)return dispatch(tipsReveal({message:'請選擇收穫地址',type:'error'})) 
    }
    if(list[0].is_approve == 0){
      dispatch(ShopBool(true))
      dispatch(ShopBuyId({buyid:paramter.buyid,address:addressValue}))
      return 
    }
    const {data,code,msg} = await api.shop.PostCartPay({
      pick_up_address:addressValue,
      buy_id:paramter.buyid
    })
    if(code != 0)return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(BalanceAction())
    dispatch(ShopAction())
    history.replace(`/sharing/paymentStatus/?is_share=${0}`)
  }
  const handleClose = async (id)=>{
    const {data,code,msg} = await api.shop.DeleteCart({
      product_id:id
    })
    if(code != 0){
      dispatch(tipsReveal({message:'Deletion failure',type:'error'}))
      return 
    } 
    list.splice(list.findIndex(item => item.product_id=== id), 1)
    setList([...list])
  }
  const handlePrice = (e)=>{
    if(e.is_veg == 0){
      var num = Precise(e.price , Math.floor(e.num))
      return Math.ceil(Number(num))
    }else{
      if(e.per_units == 'jin'){
        return Math.ceil(Number((e.num * e.price)))
      }else if(e.per_units == 'jin_liang'){
        return Math.ceil(Number((e.num * 10 * e.price)))
      }
    }
    
  }
  const handleStatistics = ()=>{
    return list.reduce((sum, item) => sum + handlePrice(item), 0)
  }
  const handleleave = ()=>{
    setTimeout(()=>{
      TipsRef.current.handleOpen()
    },100) 
    
  }
  return (
    <>
    <ProhibitBack open={TradingBool} close={()=>handleleave(false)} />
    <div className='order'>
      <TipsDiv ref={TipsRef} setTradingBool={setTradingBool} />
      <div className="order-head">
        <div className="order-head_left" onClick={handleBack}>
          <img src={Back} alt="" />
          <span className='head-left_text'>Back</span>
        </div>
        <div className="order-head_right">
          <span>你有</span>
          <span className="head-right_money">{money}</span>
          <span className="head-right_unit">時分</span>
        </div>
      </div>
      <div className="order-text">
        {paramter.type == 'scene' ? '即場交易':'預訂貨品'}
      </div>
      <div className="order-content">
      {list.map((item,index)=>item.is_veg == 0 ?<ProductBox key={item.product_id} item={item} handleClose={handleClose} handlePrice={handlePrice} handleUpload={handleUpload} />:<VegetableBox key={item.product_id} item={item} handlePrice={handlePrice} handleUpload={handleUpload}/>)}
      </div>
      <div className="order-compute">
        <div className="order-compute_statistics">
          <div className="order-compute_statistics-label">
            總計
          </div>
          <div className="order-compute_statistics-money">
            <span className="statistics-money_amount">
            {handleStatistics()}
            </span>
            <span className="statistics-money_unit">
              時分
            </span>
          </div>
        </div>
        <Divider />
        <div className="order-compute_Remaining">
          <div className="order-compute_statistics-label">
            剩餘時分 
          </div>
          <div className="order-compute_statistics-money">
            <span className="statistics-money_amount">
            {Math.ceil((money - handleStatistics()))}
            </span>
            <span className="statistics-money_unit">
              時分
            </span>
          </div>
        </div>
      </div>
      {paramter.type != 'scene' && <div className="order-address">
        <div className="order-address_text">
          選擇取貨時間及地點：
        </div>
        <div className="order-address_item">
          {addressList?.map((item,index)=><div className=
          {`${addressValue == item ? 'order-address_item-card_active' : ''} order-address_item-card`} onClick={()=>setAddressValue(item)}>
            <div className="order-address_item-card-date">{item.date} {item.start_time}-{item.end_time}</div>
            <div className="order-address_item-card-address">地點：{item.address}</div>
          </div>)}
        </div>
      </div>}
      <div className="order-button">
        <Button variant="outlined" color="primary" onClick={()=>handleBack()}>返回</Button>
        <Button variant="contained" color="primary" onClick={()=>handleSubmit()}>確認</Button>
      </div>
      {/* <OrderDialogDiv ref={OrderDialogRef} address={addressValue}/> */}
    </div>
    </>
  )
}

function ProductBox({item,handleClose,handlePrice,handleUpload}){
  return (
  <>
    <div key={item.product_id} className="cart-content-item">
      {/* <img src={IconClose} alt="" className='content-item_close' onClick={()=>handleClose(item.product_id)} /> */}
      <div className="content-item_left">
        <img className="content-item_left-img" src={api.url + item.banner} alt="" />
        <div className="content-item_left-info">
          <div className="item-info-title">
            <span>{item.vegetable_name}</span>
          </div>
          <div className="item-info-container">
            <IconButton color="primary" disabled={item.amount<=1} onClick={()=>handleUpload(item.product_id,{num:item.num-1})}>
              <img src={subtract} alt="" />
            </IconButton>
            <div className="container_amount">
              {Math.floor(item.num)}
            </div>
            <IconButton color="primary" onClick={()=>handleUpload(item.product_id,{num:Number(item.num)+1})}>
              <img src={add} alt="" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="content-item_right">
        <div className="content-item_right-money">
          {handlePrice(item)} <span className='right-money_unit'>時分</span>
        </div>
      </div>
    </div>
  </>
  )
}

function VegetableBox({item,handlePrice,handleUpload}){
  // const [jin,setJin] = useState(0)
  // const [liang,setLiang] = useState(0)
  const [formData,setFormData] = useState({
    jin:'',
    two:'',
  })
  useEffect(()=>{
    var count = Number(item.num)
    if(item.per_units == 'jin'){
      setFormData({ ...formData, ['jin']: count })
    }else{
      setFormData({ ...formData, ['two']: count })
    }
    // var jin_string = String(count).split('.')[0]
    // var liang_string = String(count).split('.')[1]
    // setJin(Number(jin_string))
    // setLiang(Number(liang_string))
  },[item])
  const handleChange = (newValue)=>{
    handleUpload(item.product_id,{num:newValue})
    // var num;
    // if(event.target.name == 'jin'){
    //   if(Number(liang) == 0){
    //     num = Number(event.target.value);
    //   }else{
    //     num = Number(event.target.value) + Number(liang) / 10;
    //   }
    // }else{
    //   if(event.target.value > 10){
    //     num = jin + 9 / 10;
    //   }else{
    //     num = jin + Number(event.target.value) / 10;
    //   }
    // }
    // handleUpload(item.product_id,{num:num})
  }
  return (
    <>
      <div className="vegetable-card">
        <div className="vegetable-card_title">
          <span>{item.product_type}</span>
        </div>
        <div className="vegetable-card_main">
          <div className="vegetable-card_main-input">
            {item.per_units == 'jin' && <WeightInput label="斤" name="jin" formData={formData} setFormData={setFormData} max={item.purchase_limit} value={formData.jin} change={handleChange}/>}
            {item.per_units != 'jin' && <WeightInput label="兩" name="two" formData={formData} setFormData={setFormData} max={item.purchase_limit} value={formData.two} change={handleChange}/>}
          </div>
          <div className="vegetable-card_main-money">
            {/* {Number(item.num * item.price)} */}
            {handlePrice(item)}
            <span>時分</span>
          </div>
        </div>
      </div>
    </>
  )
}