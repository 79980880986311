import {shareObj,requestObj} from './district.js'
export function DateFormat(date){
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = new Date(date);
    const dayName = days[day.getDay()];
    const monthName = months[day.getMonth()];
    const dayNumber = day.getDate();
    const year = day.getFullYear();
    // ${year}
    return `${dayName} ${monthName} ${dayNumber} `;
}

// 省略中間内容
export const MethodOmit = (e)=>{
  if(!e)return 
  var str = e;
  var last = 0;
  var all = str.length;
  var fisrt = str.substring(0,5);
  // 没有中文括号（
  if (str.lastIndexOf('（') == -1) {
      // 也没有英文括号(
      if (str.lastIndexOf('(') == -1) {
          last = all - 17;
      }else{
          // 有英文括号(，就从英文括号开始截取
          last = str.lastIndexOf('(');
      }
  }else{
      last = str.lastIndexOf('（');
  }
  // 如果长度大于13个字符才显示省略号
  if (all > 13) {
    return fisrt+" ... "+str.substring(last,all);
  }
}

export const ServiceType = (e,type)=>{
  if(type == 'name'){
    return shareObj.filter(item=>item.value == e)[0]?.label
  }else{
    return shareObj.filter(item=>item.value == e)[0].type
  }
}
export const RequestType = (e,type)=>{
  if(type == 'name'){
    return requestObj.filter(item=>item.value == e)[0]?.label
  }else{
    return requestObj.filter(item=>item.value == e)[0].type
  }
}
// 撥號
export const whatsContact = (e)=>{
  const whatsappUrl = `https://wa.me/${e}`;
  window.open(whatsappUrl);
}

// 获取周几
export function DateWeeks(date){
  const days = ["星期日","星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
  const day = new Date(date);
  const dayName = days[day.getDay()];
  return `${dayName} `;
}