import api from '@/api'
import {useEffect,useState,useCallback} from 'react'
import { useSelector } from 'react-redux'

// 本區市集
// is_publid 0 本區 1 區區通
export function useLocalList(formData){
  // 机构产品
  const [organization,setOrganization] = useState([])
  // 购物日
  const [shopping,setShopping] = useState([])
  // 物品交易
  const [goods,setGoods] = useState([])
  // 活动
  const [event,setEvent] = useState([])
  // 服务请求
  const [request,setRequest] = useState([])
  // 用户发布的活动
  const [userEvent,setUserEvent] = useState([])
  
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    if(!redusxUser.user_type)return 
    getData()
  },[redusxUser,formData])
  const getData = async()=>{
    const organizations = await api.home.GetCommonProduct({
      platform_id:redusxUser.user_type,
      new_product_type:formData.filter_type,
      is_evente:2,
    });
    if(organizations.data.length == undefined) 
      setOrganization([])
    else 
      setOrganization(organizations.data)
    const goodss = await api.home.GetCommonEventActive({
      platform_id:redusxUser.user_type,
      is_share:1,
      is_publid:0,
      is_product:1,
      filter_type:formData.filter_type,
      friend_type:(formData.publisher==1||formData.publisher==2) ? formData.publisher:null

    });
    if(goodss.data.length == undefined) 
      setGoods([])
    else 
      setGoods(goodss.data)
    const shoppings = await api.home.GetCommonEvent({
      platform_id:redusxUser.user_type,
      filter_type:formData.filter_type
    });
    if(shoppings.data.length == undefined) 
      setShopping([])
    else 
      setShopping(shoppings.data)
    const events = await api.home.GetAdminProduct({
      platform_id:redusxUser.user_type,
      // is_share:1,
      is_publid:0,
      new_product_type:formData.filter_type
    });
    if(events.data.length == undefined) 
      setEvent([])
    else
      setEvent(events.data)
    const requests = await api.home.GetCommonEventActive({
      platform_id:redusxUser.user_type,
      is_share:0,
      is_publid:0,
      filter_type:formData.filter_type2,
      friend_type:(formData.publisher==1||formData.publisher==2) ? formData.publisher:null
    });
    if(requests.data.length == undefined) 
      setRequest([])
    else
      setRequest(requests.data)
    const userEvents = await api.home.GetCommonEventActive({
      platform_id:redusxUser.user_type,
      is_share:1,
      is_publid:0,
      is_product:0,
      filter_type:formData.filter_type,
      friend_type:(formData.publisher==1||formData.publisher==2) ? formData.publisher:null
    });
    if(userEvents.data.length == undefined) 
      setUserEvent([])
    else
      setUserEvent(userEvents.data)
  }
  return {organization,goods,shopping,event,request,userEvent}
}
// 區區通市集
export function useAllList(formData){
  const [community1,setCommunity1] = useState([])
  const [community2,setCommunity2] = useState([])
  const [community3,setCommunity3] = useState([])
  const type = ''
  useEffect(()=>{
    if((formData.publisher != 'user' && formData.publisher != '1' && formData.publisher != '2') || formData.publisher == ''){
      getDataInstitution()
    }else{
      console.log('个人')
      getDataUser()
    }
  },[formData])
  const getDataInstitution = async()=>{
    const communitys1 = await api.home.GetCommonEvent({
      platform_id:1,
      filter_type:formData.filter_type
    });
    if(communitys1.data.length == undefined) setCommunity1([])
    else setCommunity1(communitys1.data)
    const communitys2 = await api.home.GetCommonEvent({
      platform_id:2,
      filter_type:formData.filter_type
    });
    if(communitys2.data.length == undefined) setCommunity1([]) 
    else setCommunity2(communitys2.data)
    const communitys3 = await api.home.GetCommonEvent({
      platform_id:3,
      filter_type:formData.filter_type
    });
    if(communitys3.data.length == undefined) setCommunity3([]) 
    else setCommunity3(communitys3.data)
    // const products = await api.home.GetAdminProduct();
    // if(products.data.length == undefined) setProduct({data:[]}) 
    // else setProduct(products)

  }
  const getDataUser = async()=>{
    const communitys1 = await api.home.GetCommonEventActive({
      platform_id:1,
      is_share:2,
      filter_type:formData.filter_type,
      friend_type:(formData.publisher==1||formData.publisher==2) ? formData.publisher:null
    });
    if(communitys1.data.length == undefined) 
      setCommunity1([])
    else
      setCommunity1(communitys1.data)
    const communitys2 = await api.home.GetCommonEventActive({
      platform_id:2,
      is_share:2,
      filter_type:formData.filter_type,
      friend_type:(formData.publisher==1||formData.publisher==2) ? formData.publisher:null

    });
    if(communitys2.data.length == undefined) 
      setCommunity2([])
    else
      setCommunity2(communitys2.data)
    const communitys3 = await api.home.GetCommonEventActive({
      platform_id:3,
      is_share:2,
      filter_type:formData.filter_type,
      friend_type:(formData.publisher==1||formData.publisher==2) ? formData.publisher:null

    });
    if(communitys3.data.length == undefined) 
      setCommunity3([])
    else
      setCommunity3(communitys3.data)
  }
  return {community1,community2,community3}
}
// 获取消费使用情况
export function useConsumption(){
  const [consumption,setConsumption] = useState({})
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    if(!redusxUser.user_type)return 
    handleConsumption()
  },[redusxUser])
  const handleConsumption = async()=>{
    const {data,code,msg} = await api.home.GetUserPublicMoneyLimit()
    setConsumption(data)
  }
  return {consumption}
}
// 活動、商品收藏
export function useCollect(){
  const collect = useCallback(async(detail)=>{
    var collect_type = 0
    if(detail.is_share == undefined){
      collect_type =0
    }else{
      collect_type = 1
    }
    const {data,code,msg} = await api.user.PostUserCollect({
      event_id:detail?.event_id || detail?.id,
      collect_type
    })
    return {data,code,msg}
  },[])
  return {collect}
}
// 商品詳情
export function useEventDetail(id){
  const [detail,setDetail] = useState({})
  useEffect(()=>{
    AcquireEventDetail()
  },[id])
  const AcquireEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventDetail({
      event_id:id
    })
    setDetail(data)
  }
  return detail
}
// 活動詳情
export function useShearDetail(id){
  const [details,setDetail] = useState({})
  useEffect(()=>{
    refresh()
  },[id])
  const refresh = ()=>{
    AcquireEventDetail()
  }
  const AcquireEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventActiveDetail({
      event_id:id
    })
    setDetail(data)
  }
  return {details,refresh}
}

// 產品詳情
export function useProductDetail(id){
  const [detail,setDetail] = useState({})
  useEffect(()=>{
    refresh()
  },[id])
  const refresh = ()=>{
    AcquireEventDetail()
  }
  const AcquireEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonProductDetail({
      product_id:id
    })
    setDetail(data)
  }
  return {detail,refresh}
}