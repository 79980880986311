const TokenKey = 'sfqqttoken'
const I18n = 'sfqqti18n'
const Cipher = 'sfqqtCipher'
const Initiate = 'initiate'
// token
export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}
export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

// i18n
export function getI18n() {
  return localStorage.getItem(I18n)
}
export function setI18n(e) {
  return localStorage.setItem(I18n, e)
}
export function removeI18n() {
  return localStorage.removeItem(I18n)
}

export function getCipher() {
  return localStorage.getItem(Cipher)
}
export function setCipher(e) {
  return localStorage.setItem(Cipher, e)
}
export function removeCipher() {
  return localStorage.removeItem(Cipher)
}

export function getInitiate() {
  return localStorage.getItem(Initiate)
}
export function setInitiate(e) {
  return localStorage.setItem(Initiate, e)
}
export function removeInitiate() {
  return localStorage.removeItem(Initiate)
}
