import './index.less'
import React,{useEffect, useState} from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import {useHistory,useLocation } from 'react-router-dom'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {useUserInfo} from '@/hooks/user.js'
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import photo_camera from '@/assets/images/user/photo_camera.svg'
import photo_library from '@/assets/images/user/photo_library.svg'
import Dots from '@/assets/images/user/Dots.svg'
import BackIcon from '@/assets/images/user/back.svg'
import api from '@/api'
import {UserAction,tipsReveal} from '@/redux/actions'
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import {region,district,education,otjStatus,income,matrimony,ageDistribution,membership} from '@/utils/district.js'
const minDate = new Date();
export default function UserNew(){
  const { t } = useTranslation()
  const histroy = useHistory()
  const paramter = useParameter()
  const dispatch = useDispatch()
  const {edit} = useUserInfo()
  const [progress,setProgress] = useState(0)
  const [status,setStatus] = useState(true)
  const [disabled,setDisabled] = useState(false)
  const [imgDisabled,setImgDisabled] = useState(false)
  const [state,setState] = useState({
    phone_number:'',
    username: "", 
    image: "", 
    first_name: "", 
    last_name: "", 
    gender: "", 
    birth: "", 
    address: "", 
    district: "", 
    region: "", 
    email: "", 
    edu_level: "", 
    employment_status: "", 
    household_income: "", 
    marital_status: "", 
    of_members: '', 
    age_distribution: "", 
    personal_skills: "" ,
    hobby:[],
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    setProgress(Number(paramter.progress) || 0)
  },[])
  useEffect(()=>{
    if(redusxUser.first_name){
      setDisabled(true)
      setImgDisabled(true)
    }
    setState({...redusxUser})
  },[redusxUser])
  const handleCancel = ()=>{
    if(progress == 0|| disabled){
      window.history.go(-1)
    }else{
      var num = progress - 25
      setProgress(num)
    }
  }
  const handleImgChange = (e)=>{
    console.log(e)
    setImgDisabled(false)
    // histroy.replace(`/user/new/?progress=${25}`)
  }
  const handleNext = async()=>{
    setStatus(true)
    if(progress == 0){
      if(!state.image){
        dispatch(tipsReveal({message:'請上傳頭像',type:'error'}))
        return 
      }
    }else if(progress == 25){
      if(!state.first_name)return setStatus(false)
      if(!state.last_name)return  setStatus(false)
      // if(!state.username)return setStatus(false)
      if(!state.gender)return setStatus(false)
      if(!state.birth)return  setStatus(false)
    }else if(progress == 50){
      if(!state.address)return setStatus(false)
      if(!state.district)return  setStatus(false)
      if(!state.region)return setStatus(false)
    }
    else if(progress == 75){
      if(!state.edu_level)return setStatus(false)
      if(!state.employment_status)return setStatus(false)
    }else{
      handleSave()
    }
    if(progress<100){
      var num = progress + 25
      setProgress(num)
    }
  }
  const handleSave = async () =>{
    if(state.hobby.length == 0){
      state.hobby = []
    }
    const {data,code,msg} = await edit(state)
    if(code == 0) window.history.go(-1)
  }
  return (
    <>
      <div className='new-user'>
        <LinearProgress variant="determinate" value={progress} />
        <div className="new-user_main">
          {progress == 0 && <Avatar state={state} disabled={disabled} onChange={handleImgChange} />}
          {progress == 25 && <Message state={state} setState={setState} status={status} disabled={disabled}/>}
          {progress == 50 && <Contact state={state} setState={setState} status={status} disabled={disabled}/> }
          {progress == 75 && <Education state={state} setState={setState} status={status} disabled={disabled}/>}
          {progress == 100 && <Family state={state} setState={setState} status={status} disabled={disabled}/>}
        </div>
        <div className="new-user_buttom">
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            {(progress == 0 || disabled) ?t('public.cancel'):'上一步'}
          </Button>
          {!disabled && <Button variant="contained" color="primary" onClick={handleNext}>
            {progress != 100?'下一步':'儲存'}
          </Button>}
          {disabled && <Button variant="contained" color="primary" disabled={imgDisabled} onClick={handleSave}>
            儲存
          </Button>}
        </div>
      </div>
    </>
  )
}

function Avatar({state,onChange}){
  const [anchorEl, setAnchorEl] = useState(false);
  const [avatar,setAvatar] = useState(state?.image)
  const handleClick = ()=>{
    setAnchorEl(true);
  }
  const handleUploadImage = async (e)=>{
    const image = e.target.files[0]
    const formData = new FormData();
    formData.append('file', image);
    handleUpload(formData)
  }
  const handleUploadShoot = (e)=>{
    const image = e.target.files[0]
    const formData = new FormData();
    formData.append('file', image);
    handleUpload(formData)
  }
  const handleUpload = async(formData)=>{
    const {data,code,msg} = await api.user.PostCommonFile(formData)
    if(code != 0)return 
    setAvatar(data.src)
    onChange(data.src)
    state.image = data.src
  }
  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <>
    <div className="user-main_avatar">
      <label onClick={handleClick}  className="avatar-box">
        {!state.image?<div className="avatar-box_text">
          上載頭像
        </div>:<img src={api.url + state.image} alt="" />}
      </label>
      <input accept="image/*" id="icon-button-file" type="file" onChange={handleUploadImage}/>
      <input accept="image/*" name="ipaddress" id="icon-button-shoot" type="file" onChange={handleUploadShoot}/>
      <Menu
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem className="browse-file" onClick={handleClose}>
        瀏覽文件</MenuItem>
        <MenuItem onClick={handleClose}>
          <label htmlFor="icon-button-file">
            <span>照片庫</span>
            <img src={photo_library} alt="" />
          </label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <label htmlFor="icon-button-shoot">
            <span>拍照</span>
            <img src={photo_camera} alt="" />
          </label>
        </MenuItem>
        <MenuItem onClick={handleClose}> 
          <label htmlFor="icon-button-file">
            <span>瀏覽</span>
            <img src={Dots} alt="" />
          </label>
        </MenuItem>
      </Menu>
      
    </div>
    </>
  )
}

function Contact({state,setState,status,disabled}){
  const { t } = useTranslation()
  const [districtOpen,setDistrictOpen] = useState(false)
  const [regionOpen,setRegionOpen] = useState(false)
  const handleDistrictChange = (event)=>{
    setState({ ...state, [event.target.name]: event.target.value })
    setRegionOpen(true)
  }
  const handleDistrictClose=() =>{
    setDistrictOpen(false)
  }
  const handleDistrictOpen=() =>{
    setDistrictOpen(true)
  }
  const handleRegionClose = ()=>{
    setRegionOpen(false)
  }
  const handleRegionOpen = ()=>{
    setRegionOpen(true)
  }
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.contactInformation')}
        </div>
        <TextField
          label={`${t('new.residentialAddress')}`}
          name='address'
          multiline
          variant="outlined"
          defaultValue={state.address}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          disabled={disabled}
          // helperText={state.address || status?'':t('new.pleaseEnter')+ t('new.residentialAddress')}
        />
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.area')}*</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            name='district'
            onClose={handleDistrictClose}
            onOpen={handleDistrictOpen}
            open={districtOpen}
            value={state.district}
            onChange={(event)=>handleDistrictChange(event)}
            disabled={disabled}
          > 
            <div className="select-head">選擇區域</div>
            {region.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
          </Select>
          {state.district || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.area')}</FormHelperText>}
        </FormControl>
        {state.district && <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.district')}*</InputLabel>
          {state.district && <Select
            name='region'
            value={state.region}
            onClose={handleRegionClose}
            onOpen={handleRegionOpen}
            open={regionOpen}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled || !state.district}
          >
            <div className="select-head" onClick={()=>handleDistrictOpen()}>
              <img className="select-head_back" src={BackIcon} />
              {state?.district}
            </div>
            {district[state.district]?.map((item,index)=>
              <MenuItem key={index} value={item}>{item}</MenuItem>
            )}
          </Select>}
          {state.region || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.district')}</FormHelperText>}
        </FormControl>}
        <TextField
          label={`${t('new.mobilePhoneNumber')}*`}
          name='phone_number'
          multiline
          variant="outlined"
          defaultValue={state.phone_number}
          disabled={state.phone_number}
        />
        <TextField
          label={`${t('new.electronicMail')}`}
          name='email'
          multiline
          variant="outlined"
          defaultValue={state.email}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          disabled={disabled}

        />
      </div>
    </>
  )
}

function Message({state,setState,status,disabled}){
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const { t } = useTranslation()
  const handleChangeDate = (date)=>{
    const dt = date?.$d
    var y = dt.getFullYear();
	  var m = dt.getMonth() + 1;
	  m = m < 10 ? ('0' + m) : m;
	  var d = dt.getDate();
 	  d = d < 10 ? ('0' + d) : d;
    var h = dt.getHours()<10?('0' + dt.getHours()) : dt.getHours();
	  var minute = dt.getMinutes();
	  minute = minute < 10 ? ('0' + minute) : minute;
	  let time = y + '-' + m + '-' + d;
    setState({ ...state, ['birth']: time })
  }
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.basicMessage')}
        </div>
        <TextField
          label={`${t('new.lastName')}*`}
          name='first_name'
          disabled={disabled}
          multiline
          variant="outlined"
          defaultValue={state.first_name}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.first_name || status?'':t('new.pleaseEnter')+t('new.lastName')}
        />
        <TextField
          label={`${t('new.theName')}*`}
          name='last_name'
          disabled={disabled}
          multiline
          variant="outlined"
          defaultValue={state.gender}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.last_name || status?'':t('new.pleaseEnter')+t('new.theName')}
        />
        <TextField
          label={`${t('new.nickname')}`}
          name='username'
          multiline
          variant="outlined"
          defaultValue={state.username}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          // helperText={state.username || status?'':t('new.pleaseEnter')+t('new.nickname')}
        />
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">
            {t('new.sex')}*
          </InputLabel>
          <Select
            name='gender'
            value={state.gender}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled}
          >
            <MenuItem value={'男'}>男</MenuItem>
            <MenuItem value={'女'}>女</MenuItem>
          </Select>
          {state.gender || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.sex')}</FormHelperText>}
        </FormControl>
        {/* <TextField
          label={`${t('new.dateOfBirth')}*`}
          name='birth'
          multiline
          variant="outlined"
          defaultValue={state.birth}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.birth || status?'':t('new.pleaseEnter')+t('new.dateOfBirth')}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker 
            label={`${t('new.dateOfBirth')}*`}
            name='birth'
            defaultValue={dayjs(state.birth)}
            value={dayjs(state.birth)}
            onChange={handleChangeDate}
            format="YYYY/MM/DD"
            maxDate={dayjs(minDate)}
            disabled={disabled}
          />
          {state.birth || status?'':<FormHelperText>{t('new.pleaseEnter')+t('new.dateOfBirth')}</FormHelperText>}
        </LocalizationProvider>
      </div>
    </>
  )
}

function Education({state,setState,status,disabled}){
  const { t } = useTranslation()
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.educationAndIncome')}
        </div>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.educationalLevel')}</InputLabel>
          <Select
            name='edu_level'
            value={state.edu_level}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled}
          > 
            <div className="select-head">選擇教育水平</div>
            {education.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
          </Select>
          {state.edu_level || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.educationalLevel')}</FormHelperText>}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.on-the-jobStatus')}*</InputLabel>
          <Select
            name='employment_status'
            value={state.employment_status}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled}
          >
            <div className="select-head">選擇在職狀況</div>
            {otjStatus.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
          </Select>
          {state.employment_status || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.on-the-jobStatus')}</FormHelperText>}
        </FormControl>
      </div>
    </>
  )
}

function Family({state,setState,status,disabled}){
  const { t } = useTranslation()
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.familyDetails')}
        </div>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.monthlyHouseholdIncome')}</InputLabel>
          <Select
            name='household_income'
            value={state.household_income}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled}
          >
            <div className="select-head">選擇家庭每月收入</div>
            {income.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
          </Select>
          {/* {state.household_income || status?'':<FormHelperText>請選擇家庭每月收入</FormHelperText>} */}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.maritalStatus')}</InputLabel>
          <Select
            name='marital_status'
            value={state.marital_status}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled}
          >
            <div className="select-head">選擇婚姻狀況</div>
            {matrimony.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.numberOfFamilyMembers')}</InputLabel>
          <Select
            name='of_members'
            value={state.of_members}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled}
          >
            <div className="select-head">選擇家庭成員年齡分佈</div>
            {membership.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.familyMembersAgeDistributionAnd')}</InputLabel>
          <Select
            name='age_distribution'
            value={state.age_distribution}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            disabled={disabled}
          >
            <div className="select-head">選擇家庭成員人數</div>
            {ageDistribution.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
        <TextField
          label={t('new.personalSkill')}
          name='personal_skills'
          multiline
          variant="outlined"
          defaultValue={state.personal_skills}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          disabled={disabled}
        />
      </div>
    </>
  )
}