const LoginReducer = (prevState={
  formData:{
    publisher:'',
    filter_type:'',
    filter_type2:'',
  },
},action)=>{
  var newStete = {...prevState}
  switch(action.type){
    case "SCREEN" :
      newStete.formData = action.value
      return newStete 
    case "CLEAR" :
      newStete.formData = {
        publisher:'',
        filter_type:'',
        filter_type2:'',
      }
      return newStete 
    default:
      return newStete
  }
}
export default LoginReducer