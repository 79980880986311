// login
import './index.less';
import React,{useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {useNavigation} from '@/hooks/useNavigation.js'
import Button from '@mui/material/Button';
import {useHistory} from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {setToken,setCipher,getCipher} from '@/utils/auth'
import Logo from '@/assets/images/login/TExLogo.svg';
import Foundation_Logo from '@/assets/images/login/Foundation_Logo.svg';
import Chest_Logo from '@/assets/images/login/Chest_Logo.svg';
import api from '@/api'
import {loginAction,tipsReveal,UserAction,ShopAction,BalanceAction} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { stat } from 'fs-extra';
const passwordRE =/^(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
import PhoneNumber from '@/components/PhoneNumber'
export default function Login(){
  useNavigation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [status,setStatus] = useState(true)
  const [agreement,setAgreement] = useState(false)
  const [passwordText,setPasswordText] = useState('至少8個字符，1個數字和1個大寫字母')
  const [formData, setFormData] = useState({
    phone_number:'',
    password:'',
    phone_code:'852',
    bool:false,
    
  });
  useEffect(()=>{
    if(getCipher()){
      setFormData(JSON.parse(getCipher()))
    }
  },[])
  const handleSumit = async()=>{
    if(!formData.phone_number)return setStatus(false)
    if(!formData.password)return setStatus(false)
    if(!passwordRE.test(formData.password)){
      setPasswordText('至少8個字符，1個數字和1個大寫字母')
      setStatus(false)
      return
    }
    if(!agreement)return dispatch(tipsReveal({message:'請勾選協議',type:'error'}))
    const {data,code,msg} = await dispatch(loginAction(formData))
    if(code != 0){
      dispatch(tipsReveal({message:msg,type:'error'}))
      return 
    } 
    dispatch(UserAction())
    dispatch(BalanceAction())
    dispatch(ShopAction())
    history.replace('/home')
  }
  const handleSignin = ()=>{
    console.log('注册')
    history.replace('/entrance/main')
  }
  const handleAgreement = (e)=>{
    history.push(e)
  }
  return (
    <div className='login'>
      <div className="login-logo">
        <img src={Logo} alt="" />
      </div>
      <div className="login-form">
        <form action="">
          <PhoneNumber code={formData.phone_code} phone={formData.phone_number} status={status} formData={formData} setFormData={setFormData}/>
          <TextField
            label={t('login.cipher')}
            name="password"
            type="password"
            variant="outlined"
            value={formData.password}
            defaultValue={formData.password}
            autoComplete="current-password"
            onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            helperText={formData.password && passwordRE.test(formData.password)|| status?'':passwordText}
          />
          
          {/* t('login.pleaseEnter') + t('login.cipher') */}
          <div className="login-form_checkbox">
            <FormControlLabel
              control={<Checkbox checked={formData.bool}  name="bool" color="primary" onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.checked })}}/>}
              label={t('login.rememberThePassword')}
            />
            <span className="login-form_checkbox-forgot" onClick={()=>history.replace('/entrance/forgot')}>
              忘記密碼
              {/* Sign in */}
            </span>
          </div>
          <div className="login-form_checkbox">
            <FormControlLabel
              control={<Checkbox checked={agreement}  name="agreement" color="primary" onChange={(event)=>{setAgreement(event.target.checked
                )}}/>}
              label={<div>
                我已同意<span onClick={()=>handleAgreement("/entrance/clause")} className="color0075b4">《使用條款》</span>及<span className="color0075b4" onClick={()=>handleAgreement("/entrance/policy")}>《私人政策》</span>
              </div>}
            />
          </div>
          <Button variant="contained" color="primary" onClick={handleSumit}>
            {t('login.logIn')}
          </Button>
        </form>
        <Divider>或</Divider>
        <Button variant="outlined" color="primary" onClick={handleSignin}>
          註冊
        </Button>
        <div className="login-img">
          <div className="icon">
            <div className="icon-title">
              資助機構:
            </div>
            <img src={Foundation_Logo} />
          </div>
          <div className="icon">
            <div className="icon-title">
              撥款及監察機構:
            </div>
            <img src={Chest_Logo} />
          </div>
          
        </div>
      </div>
    </div>
  )
}

