// 使用条款
import './index.less'
import React,{useState,useEffect} from 'react';
import TopHead from '@/components/TopHead'

export default function Clause(){
  const [value,setValue] = useState('時分區區通')
  const [email,setEmail] = useState('support@time-ex.org')
  return (
    <div className="clause">
      <TopHead title="使用條款" />
      <div className="main">
        <div className="title">
          {value}應用程式條款及細則
        </div>
        <div className="text">
          <span className="name">{value}</span>應用程式（「本應用程式」）乃由香港聖公會麥理浩夫人中心, 香港婦女中心協會, 關注草根生活聯盟營運。無論閣下是否本應用程式之登記用戶，當閣下進入或使用本應用程式時，即表示閣下已閱讀、理解並同意接受以下條款及細則之約束，以及資料的收集和應用。請務必細閱本條款及細則以確保閣下了解每項條款。如果閣下不了解或不接受當中任何條款，請勿使用本應用程式。
        </div>
        <div className="title">
          私隱政策
        </div>
        <div className="text">
          有關本應用程式的私穩政策，請參閱<span className="name">{value}</span>應用程式私隱政策。
        </div>
        <div className="title">
          使用本應用程式
        </div>
        <div className="text">
          用戶經本應用程式公開張貼或私下傳送文字、照片、圖像、視像短片、地圖或其他資料（「內容」）的時候，本機構保留行使預先審查、拒絕或移除任何經由本應用程式發佈之任何「內容」的權利。閣下在此同意不會張貼、傳送、發佈或以其他形式提供以下內容：
          <ul>
            <li>對閣下或任何其他人構成損害、損失、身體或精神上的傷害或情緒困擾的風險；</li>
            <li>對閣下或任何其他人構成個人或財產上之任何損失或損害的風險；</li>
            <li>通過張貼任何不恰當的內容，試圖傷害或剝削兒童；</li>
            <li>可能構成或促使犯罪或侵權索賠的任何訊息或內容；</li>
            <li>包含本機構認為可能是非法、有害、辱罵、種族或民族攻擊、誹謗、侵權、侵犯個人私隱或出版權利、騷擾、羞辱、中傷、威脅、褻瀆他人，或其他不恰當的任何訊息或內容；</li>
            <li>可能違法的任何訊息或內容；</li>
            <li>在任何法律或合約、或因受託關係閣下無權提供的資料（例如：因僱傭關係或保密協議下所獲得的內幕資料、專屬及機密資料）；</li>
            <li>任何侵犯專利、商標、商業秘密、版權或其他專屬權利之「內容」；</li>
            <li>任何帶有電腦病毒或任何其他電腦編碼、檔案或程式的資料用作干擾、破壞或限制任何電腦軟體、硬體或電訊設備的功能；</li>
            <li>干擾本應用程式的正常運作；故意或非故意妨礙或使本應用程式、伺服器或與本應用程式連接的網路中斷，或不遵守與本應用程式連接的網路的要求、程序、政策或規則；對本應用程式造成或可能造成不合理或不相稱之使用負荷；</li>
            <li>故意或非故意地違反任何適用的法律，包括但不限於任何國家或其他任何具法律效力的規例。</li>
          </ul>
        </div>
        <div className="text">
          未經本機構的書面同意，閣下不得利用或於任何媒體複製、分發或披露本應用程式的任何部分。
        </div>
        <div className="text">
          閣下不得使用本應用程式或其任何部分以達至任何商業宣傳目的。
        </div>
        <div className="text">
          閣下發佈、傳送或要求張貼的內容不可侵犯第三方的權利，包括但不限於任何侵犯知識產權或版權或私隱的內容。本機構有權但沒有義務拒絕及/或刪除任何本機構認為違反本條款的內容。閣下明白本機構可能會或可能不會預先審查「內容」，但本機構和其指定人士將有權（但沒有義務）自行判斷預先審查、拒絕或移除任何經由本應用程式發佈之任何「內容」。在並不影響前述的條款的前提下，本機構和其指定人士將有權移除任何違反本應用程式條款及細則或在其他方面令人反感或厭惡的「內容」。閣下同意閣下必須自行評估及承擔與使用「內容」或倚賴該等「內容」相關的所有風險。
        </div>
        <div className="title">
          第三方資訊
        </div>
        <div className="text">
          本應用程式或包含由第三方（包括其他用戶或機構）提供或從其他參考資料或途徑獲取的資訊。如此等資訊已過期或有錯漏，本機構概不負責。本機構有權但沒有義務刪除任何於本應用程式分享的內容。本機構不會個别核實由此等第三方或代理機構提供的資訊，閣下須自行評估此等資訊是否可靠、準確。本機構對此等資料概不負責，閣下必須為自己使用或依賴此等資訊的風險負上全責。
        </div>
        <div className="title">
          連結到第三方網站
        </div>
        <div className="text">
          本應用程式或會連結閣下至其他網站，閣下承認並同意本機構不必為此類連結網站所提供的資料是否準確或適用而負責。本應用程式載有其他網站的連結不表示本機構認可或推薦此等網站或此等網站的任何內容、產品、廣告或其他資料。到訪第三方網站時，閣下有責任自行了解任何可能適用的條款及細則。
        </div>
        <div className="title">
          知識產權
        </div>
        <div className="text">
          本應用程式中的所有內容，包括但不限於軟體、圖像、文字、圖形、插圖、標誌、專利、商標、服務商標、版權、相片、音頻、視頻、音樂以及所有相關的知識產權均營運機機構所有，或已合法地特許給本機構在本應用程式使用。閣下所有的下載資料（包括檔案、合成圖像、地圖或由本應用程式所產生的一切資料）只供閣下個人及非商業用途。閣下同意不會出售、許可、出租、修改、分發、複製、傳播、展示、表演、出版、改編、編輯或創建任何本應用程式的衍生作品。
        </div>
        <div className="title">
          不提供任何保證
        </div>
        <div className="text">
          使用本應用程式即表示閣下明白所有公開張貼或私下傳送的「內容」均由內容來源者全權負責。這表示閣下須為所有閣下所張貼、傳送或以其他通過本應用程式發佈之「內容」負上全部的責任。本機構無須就該等「內容」負責。 對於本應用程式資料或內容是否準確可靠，本機構不作任何種類的保證（不論是明示或隱含的），包括但不限於關於所有權或不侵權的保證或適合某一特定用途的保證，本機構亦不能確保本應用程式不含任何錯誤或病毒。閣下同意並確認使用本應用程式之任何風險均由閣下自行承擔。
        </div>
        <div className="title">
          彌償
        </div>
        <div className="text">
          閣下同意凡因：（一）閣下進入及使用本應用程式，包括任何由閣下發送或接收的數據或內容；（二）閣下違反本條款的任何部份；（三）閣下違反任何第三方權利，包括但不限於私隱或知識產權的任何權利；（四）閣下違反任何適用法律、法規或規章；（五）閣下的內容或任何通過閣下賬戶提交的內容或資訊而引起之任何索賠或損失；或（六）任何人以閣下的使用者名稱或密碼進入和使用本應用程式，而引致或產生的任何和所有索賠、損失、責任、支出或債務，和其衍生的任何費用，閣下均須負責。閣下並承諾維護、保障及彌償本機構、其所屬機構、員工、承包商、代理商、管理人員和董事因以上各項所承擔之一切損失及賠償。
        </div>
        <div className="title">
          責任限制
        </div>
        <div className="text">
          在適用法律容許之範圍內，本機構及其員工、管理人員、董事、會員、代理商、代表、子公司或關聯機構不會對閣下使用本應用程式或不能使用本應用程式所造成的損失或損害承擔責任，不論此等損失或損害屬於直接或間接的，包括但不限於利潤損失、商譽受損、數據丟失或任何其他損害或損失。在任何情況下，對於由駭客攻擊或其他未經授權進入或使用本應用程式或閣下帳戶造成的損害或損失，本機構不會承擔任何責任。此條款適用於任何形式的責任，包括合約、侵權、疏忽、嚴格責任或其他責任。即使本機構已得知有機會造成損失，本條款仍然適用。
        </div>
        <div className="text">
          本機構對下列情況一概無須負責：在使用本應用程式或資訊傳輸過程中發生的錯誤、遺漏、中斷、取消、延誤；或通訊線路故障、失竊或損毀；未經授權的接入或變更參與程式；任何線路、電腦在線系統、伺服器或供應商、電腦設備或軟體的問題或技術故障；或由於服務供應商的技術問題或線路擁塞而造成或發生故障或參與失敗；以致資料遺失或錯漏；或幾種情況兼有，包括用戶或其他人員由於使用本應用程式而造成其電腦硬體或軟體損壞。
        </div>
        <div className="text">
          閣下需全權負責閣下與其他用戶的互動，本機構有權但並沒有義務監控或處理閣下與其他用戶之間的糾紛。對於閣下與其他用戶的互動或任何用戶之間的作為或不作為，本機構不會承擔任何責任。
        </div>
        <div className="title">
          終止帳戶
        </div>
        <div className="text">
          本機構可能會在不另行通知的情況下更新本應用程式的內容、停止提供本應用程式或停止提供本應用程式的部份或全部功能，或為閣下或其他用戶建立使用限制。
        </div>
        <div className="text">
          本機構或閣下可以終止／刪除閣下的帳戶。在不影響前述規定的情況下，本機構可以以任何理由，包括但不限於本機構認為閣下違反條款及細則，全權酌情決定終止閣下的帳戶或禁止閣下使用本應用程式。本機構可禁止閣下使用本應用程式而無須事先通知，並可隨時停用或刪除閣下的帳戶及所有帳戶數據及／或禁止閣下繼續使用本應用程式。如果閣下希望終止帳戶並將個人資料（包括用戶頭像）從本應用程式刪除，閣下可以傳送電郵到<span className="email">{email}</span>告知。閣下的帳戶一旦停用，本機構將從伺服器上刪除閣下的個人資料及數據資料，並向閣下傳送電郵確認。本條款並不適用於閣下於本應用程式上載及／或發佈之資料（包括但不限於相片、故事及資訊）。
          <div>一旦被終止使用本應用程式，閣下仍會繼續受本條款的約束。</div>
        </div>
        <div className="title">
          修訂本條款及細則
        </div>
        <div className="text">
          本機構可不時修改、增添或刪除此等條款及細則。條款及細則經修訂後，閣下如繼續使用本應用程式，即表示同意受該等修訂約束。
        </div>
        <div className="title">
          適用法律
        </div>
        <div className="text">
          本條款及細則受香港法律管轄。
        </div>
        <div className="date">
          2024年9月更新
        </div>
      </div>
    </div>
  )
} 