// vegetable
import './index.less'
import {useState,useEffect,useRef,forwardRef} from 'react'
import {Link} from 'react-router-dom'
import Divider from '@mui/material/Divider';
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {useEventDetail} from '@/hooks/home.js'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Back from '@/assets/images/shop/back.svg'
import Personal from '@/components/Personal'
import api from '@/api'
import {shiftTime} from '@/utils/shifttime'
import { useTranslation } from 'react-i18next'
import {DateFormat,DateWeeks} from '@/utils/custom'
export default function Vegetable(){
  const { t } = useTranslation()
  const paramter = useParameter()
  const histroy = useCustomHistory()
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  const detail = useEventDetail(paramter.id)
  const [addressList,setAddlessList] = useState([])
  useEffect(()=>{
    if(!detail?.pick_up_address)return 
    setAddlessList(JSON.parse(detail?.pick_up_address))
  },[detail])
  const handleBack = ()=>{
    window.history.go(-1)
  }
  const handlePersonal = ()=>{
    // console.log(detail)
    // personalRef.current.handleOpen({uid:detail.admin_uid})
  }
  return (
    <div className='vegetable'>
      <PersonalDiv ref={personalRef} />
      <div className="vegetable-head">
        <div className="vegetable-head_left" onClick={handleBack}>
          <img src={Back} alt="" />
          <span className='head-left_text'></span>
        </div>
      </div>
      <div className="vegetable-info">
        <Avatar onClick={()=>handlePersonal()} alt={detail?.username} src={api.url + detail.avatar} />
        <div className="vegetable-info_name">
          {detail?.username}
        </div>
      </div>
      <div className="vegetable-main">
        <div className="vegetable-main_title">
          {detail?.title}
        </div>
        <div className="vegetable-main_date">
          {detail?.event_type == 'Product' && '現成商品' ||  detail?.event_type == 'vegetable' && '蔬菜日'}
          <Divider orientation="vertical" flexItem />
            {DateWeeks(detail?.start_time)}
          <Divider orientation="vertical" flexItem />
          {shiftTime(detail?.create_time)}
        </div>
        <div className="vegetable-main_intro">
          {detail?.event_desc}
        </div>
        <div className="vegetable-main_img">
          {detail.banner && <img src={api.url + detail.banner} alt="" />}
          {detail.image && detail.image.split(",").map(item=>item && 
          <img key={item} src={api.url + item}/>)}
        </div>
        {addressList?.map((item,index)=><div key={index} className="vegetable-main_list">
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {/* 交收地點{index + 1}： */}
              活動地點：
            </label>
            <div className="list-item_value">
              {item?.address}
            </div>
          </div>
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {/* {t('home.date')}: */}
              {/* 開始時間{index + 1}： */}
              活動時間：
            </label>
            <div className="list-item_value">
              {item?.date} {item.start_time} - {item.end_time}
            </div>
          </div>
        </div>)}
        
        <div className="vegetable-main_button">
          {/*  onClick={handleShopping} */}
          {detail.event_type == "vegetable" && <Link to={`/shopping/food/?id=${detail.event_id}`}>
            <Button variant="contained" color="primary">
              前往購物
            </Button>
          </Link>}
          {detail.event_type != "vegetable" && <Link to={`/shopping/shop/?id=${detail.event_id}`}>
            <Button variant="contained" color="primary">
              前往購物
            </Button>
          </Link>}
        </div>
      </div>
    </div>
  )
}

