// vegetable
import {useState,useEffect,useRef,forwardRef,useCallback} from 'react'
import {Link} from 'react-router-dom'
import Divider from '@mui/material/Divider';
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {useProductDetail} from '@/hooks/home.js'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Back from '@/assets/images/shop/back.svg'
import Personal from '@/components/Personal'
import api from '@/api'
import {shiftTime} from '@/utils/shifttime'
import { useTranslation } from 'react-i18next'
import {ServiceType} from '@/utils/custom.js'
import AppendDialog from './appendDialog'
export default function ProductDetail(){
  const { t } = useTranslation()
  const paramter = useParameter()
  const histroy = useCustomHistory()
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  const {detail} = useProductDetail(paramter.id)
  const AppendDialogDiv = forwardRef(AppendDialog)
  const AppendDialogRef = useRef(null)
  const [addressList,setAddlessList] = useState([])
  useEffect(()=>{
    if(!detail?.pick_up_address)return 
    setAddlessList(JSON.parse(detail?.pick_up_address))
  },[detail])
  const handleBack = ()=>{
    window.history.go(-1)
  }
  const handlePersonal = ()=>{
    // console.log(detail)
    // personalRef.current.handleOpen({uid:detail.admin_uid})
  }
  const handleShopping = ()=>{

  }
  const handleAppend = useCallback((e)=>{
    AppendDialogRef.current.handleOpen(e)
  },[])
  return (
    <div className='vegetable'>
      <PersonalDiv ref={personalRef} />
      <div className="vegetable-head">
        <div className="vegetable-head_left" onClick={handleBack}>
          <img src={Back} alt="" />
          <span className='head-left_text'></span>
        </div>
      </div>
      <div className="vegetable-info">
        <Avatar onClick={()=>handlePersonal()} alt={detail?.username} src={api.url + detail?.avatar} />
        <div className="vegetable-info_name">
          {detail?.vegetable_name}
        </div>
      </div>
      <div className="vegetable-main">
        <div className="vegetable-main_title">
          {detail?.vegetable_name}
        </div>
        <div className="vegetable-main_date">
          {detail.is_event == 0 ?ServiceType(detail?.new_product_type,'name') : '報名'}
          <Divider orientation="vertical" flexItem />
          {detail?.price} {detail?.per_units}
          <Divider orientation="vertical" flexItem />
          {shiftTime(detail?.create_time)}
        </div>
        <div className="vegetable-main_intro">
          {detail?.desc}
        </div>
        <div className="vegetable-main_img">
          {detail?.banner && <img src={api.url + detail?.banner} alt="" />}
          {detail?.image && detail.image.split(",").map(item=>item && 
          <img key={item} src={api.url + item}/>)}
        </div>
        <div className="vegetable-main_list">
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              價格：
            </label>
            <div className="list-item_value">
              {detail?.price} 時分
            </div>
          </div>
        </div>
        <div className="vegetable-main_list">
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {detail.is_event== 0 ? '庫存' : '可報名人數'}：
            </label>
            <div className="list-item_value">
              {detail?.stock}
            </div>
          </div>
        </div>
        {addressList?.map((item,index)=><div key={index} className="vegetable-main_list">
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {detail.is_event== 0 ? '交收地點' : '活動地點'}{index + 1}：
            </label>
            <div className="list-item_value">
              {item?.address}
            </div>
          </div>
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {/* {t('home.date')}: */}
              {detail.is_event== 0 ? '開始時間' : '活動時間'}{index + 1}：
            </label>
            <div className="list-item_value">
              {item?.date} {item?.start_time} {item?.start_time && '-'} {item.end_time}
            </div>
          </div>
        </div>)}
        <div className="vegetable-main_list">
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {/* {t('home.date')}: */}
              {detail.is_event== 0 ? '截至時間' : '截至申請時間'}
            </label>
            <div className="list-item_value">
              {detail?.product_arrival_date}
            </div>
          </div>
        </div>
        <div className="vegetable-main_button">
          {/*  onClick={handleShopping} */}
          <Button variant="contained" color="primary" onClick={()=>handleAppend(detail)}>
            加入購物車
          </Button>
        </div>
      </div>
      <AppendDialogDiv ref={AppendDialogRef} />
    </div>
  )
}

