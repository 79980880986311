// 私人政策
import './index.less'
import React,{useState,useEffect} from 'react';
import TopHead from '@/components/TopHead'
export default function Clause(){
  const [value,setValue] = useState('時分區區通')
  const [email,setEmail] = useState('support@time-ex.org')
  const [address,setAddress] = useState('地址')
  const [date,setDate] = useState('2024年9月')
  return (
    <div class="policy">
      <TopHead title="私人政策" />
      <div className="main">
        <div className="title">
          {value}應用程式私隱政策
        </div>
        <div className="text">
          本私隱政策適用於由香港婦女中心協會, 關注草根生活聯盟機構營運（本機構 / 營運機構）運作之<span className="name">{value}</span>應用程式（「本應用程式」）。閣下下載及使用本應用程式，即代表同意接納本私隱政策所載事項。本機構會採取合理步驟以確保所有經本應用程式提交的個人資料均按香港《個人資料（私隱）條例》相關規定處理。
        </div>
        <div className="title">
          收集個人資料
        </div>
        <div className="text">
          以下為本應用程式向閣下所收集之資料種類及如何使用該等資料。請注意，閣下可在流動裝置按個人之設定禁止本應用程式使用該等功能，詳情請檢視閣下的流動裝置設定。然而，這可能會導致閣下無法使用本應用程式的全部或部份功能。
        </div>
        <div className="text">
          本應用程式或會收集並存取以下資料：
          <ul>
            <li>登記/使用帳戶時，閣下須輸入會員號碼，並提供密碼以供驗證。</li>
            <li>透過社交媒體登記：閣下可透過Facebook社交媒體平台的帳戶連結本應用程式。</li>
          </ul>
        </div>
        <div className="text">
          上述資料亦可能會用於以下用途：
          <ul>
            <li>核實身分及如閣下忘記用戶名或密碼時加以協助；</li>
            <li>分享閣下上傳至本應用式之相片、影像檔、資訊及故事。</li>
          </ul>
        </div>
        <div className="text">
          請注意，如閣下選擇不登記帳戶，只可有限度閱覽本應用程式的活動/服務。
        </div>
        <div className="title">
          資料儲存於何處
        </div>
        <div className="text">
          如閣下選擇登記帳戶，本應用程式所收集之電郵地址、姓名、密碼及閣下之任何其他個人資料，將儲存於本應用程式的後端伺服器。
        </div>
        <div className="title">
          追蹤
        </div>
        <div className="text">
          本應用程式使用追蹤軟件開發套件（SDK），Google Analytics會收集與本應用程式相關的一般及匯總統計數據，如裝置類型、到訪網站人次、本應用程式下載量等。該等統計數據用於監察、優化本應用程式的表現及作報告用途。收集這些統計數據是匿名的，本應用程式不會使用追蹤編碼收集閣下的個人資料。閣下亦可了解並查看SDK，Google Analytics的私隱聲明。
        </div>
        <div className="text">
          閣下繼續經流動裝置使用本應用程式，即同意本應用程式按本私隱政策及使用以上追蹤編碼或同類技術。
        </div>
        <div className="title">
          資料保密
        </div>
        <div className="text">
          本機構重視保障閣下的資料安全。本機構將會採取合理步驟，確保所收集、使用或披露的資料準確、完整及經常更新，並儲存在只准獲授權人士進入的保密環境。當透過本應用程式提交資料時，有關資料將被安全通訊協定（“SSL”）的保護標準加密，而儲存在伺服器中的資料也被加密。
        </div>
        <div className="text">
          然而，在互聯網上傳輸資訊並非絕對安全。本機構雖盡力保護閣下的個人資料，但無法保證傳輸的資料的安全，閣下須自行承擔任何傳輸資訊的風險。本機構無法保證不會有第三者入侵電腦、竊取資料，或不會有任何人擅自取閱資料。
        </div>
        <div className="title">
          第三方存取資料
        </div>
        <div className="text">
          本機構不會將閣下的個人資料向任何人出租或出售，但在以下情況下可能會向第三方披露：
          <ul>
            <li>提供予協助本機構運作本應用程式的第三方（包括但不限於系統託管服務供應商），以供其運作本應用程式；</li>
            <li>為遵行任何法律責任或為執行閣下與本機構之間任何具法律約束力之協議。</li>
          </ul>
        </div>
        <div className="text">當閣下透過Apple的iTunes、Google Play或任何其他平台（統稱為「平台」）下載本應用程式時，亦須受這些平台的服務條款約束。對於這些平台如何使用閣下輸入的個人資料，本機構概不負責。</div>
        <div className="title">
          第三方網站
        </div>
        <div className="text">
          本應用程式或會有連結至非本機構擁有或運作的網站。應用程式內所提供的連結是為了方便閣下使用。對該等網站的內容或該等網站所收集、持有、使用或分發閣下所提供任何個人資料的方式，本機構概不負責。本機構建議閣下以本應用程式所示連結至第三方網站時，須檢視該等網站的私隱聲明，理解該等網站如何收集、持有、使用及分發閣下提供之個人資料。到訪第三方網站時，閣下須自行了解任何可能適用的條款及細則。
        </div>
        <div className="title">
          修訂本私隱政策
        </div>
        <div className="text">
          本機構使用個人資料受當時實施的私隱政策規限。本機構或會不時修訂本私隱政策，閣下應當不時瀏覽本機構最新之私隱政策。如閣下繼續使用本應用程式，即表示閣下同意接受任何該等修訂所約束。如閣下不接受該等修訂，應立即停止使用本應用程式，並解除安裝本應用程式。
        </div>
        <div className="title">
          權利
        </div>
        <div className="text">
          如閣下登記帳戶時，曾經同意接受本機構將閣下個人資料用於直銷，本機構可能會以電郵向閣下發送直銷訊息。如閣下不想再收到直銷訊息，可發送電郵到<span className="email">{email}</span>聯絡本機構，書面提出拒收要求。
        </div>
        <div className="title">終止帳戶</div>
        <div className="text">
          如閣下希望終止帳戶並將個人資料從本應用程式刪除，閣下可傳送電郵到<span className="email">{email}</span>告知。閣下的帳戶一旦停用，本機構將從伺服器上刪除閣下的數據資料。本條款並不適用於閣下於本應用程式上載及／或發佈之資料（包括但不限於相片、故事及資訊）。
        </div>
        <div className="title">
          查閱個人資料及聯絡本機構 
        </div>
        <div className="text">
          如對本私隱政策、本機構對本應用程式的處理事宜及本應用程式的使用方式仍有疑問，或要求查閱個人資料或刪除帳戶，請聯絡：
        </div>
        <div className="address">
          <div>電郵：{email}</div>
        </div>
        <div className="date">
          {date}更新
        </div>
      </div>
    </div>
  )
} 