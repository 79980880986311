import './index.less'
import {useState,forwardRef,useRef} from 'react'
import { QrReader } from 'react-qr-reader';
import { useEffect } from 'react';
import Authority from '../Authority'
let scan = null;
export default function ScancodeMD({feedback}){
  const [facingMode, setFacingMode] = useState('environment');
  const AuthorityDiv = forwardRef(Authority)
  const AuthorityRef = useRef(null)
  useEffect(()=>{
    if(window.plus){
      try{                                     
        const res2 =  plus.navigator.checkPermission('CAMERA')
        if(res2 == 'denied') return AuthorityRef.current.handleOpen('相機')
        if(res2 == 'undetermined') return AuthorityRef.current.handleOpen('相機')
        // plus.android.requestPermissions(['android.permission.CAMERA'])// 询问
      }
      catch{
        
      }
    }
    if(window.plus){
      plus.camera.getCamera();
      startRecognize()
    }
    return()=>{
      console.log('离开')
      if (scan) scan.close();
      scan = null;
    }
  },[])
  
  // 創建掃描組件
  const startRecognize =()=> {
    scan = new window.plus.barcode.Barcode("bcid", [window.plus.barcode.QR], {
      width:'100%',
      height:'100%',
      top: 0,
      left:0,
      position:'static',
      scanbarColor: "#229FCA",
      frameColor: "#229FCA",
    });
    scan.start(); //开始扫描
    scan.onmarked = (type, result) => {
      switch (type) {
        case window.plus.barcode.QR:
          type = "QR";
          break;
        case window.plus.barcode.EAN13:
          type = "EAN13";
          break;
        case window.plus.barcode.EAN8:
          type = "EAN8";
          break;
        default:
          type = "其它" + type;
          break;
      }
      result = result.replace(/\n/g, "");
      feedback(result)
    };
  }
  return(
    <>
    {!window.plus && <div className="scan-code_md">
      <AuthorityDiv ref={AuthorityRef} />
      <div className="border_corner border_corner_left_top"></div>
      <div className="border_corner border_corner_right_top"></div>
      <div className="border_corner border_corner_left_bottom"></div>
      <div className="border_corner border_corner_right_bottom"></div>
      <div className="border_corner_line"></div>
      <QrReader
        scanDelay={300}
        onResult={(result, error) => {
          if (!!result) {
            feedback(result?.text)
          }
          if (!!error) {

          }
        }}
        facingMode= { "environment" }
      />
    </div>}
    {window.plus && <div id="bcid" class="bcid">
      <div>..加載中..</div>
    </div>}
    </>
  )
}