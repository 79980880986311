import './index.less'
import React ,{useState,useImperativeHandle} from 'react'
import {useNavigation} from '@/hooks/useNavigation.js'
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch,useSelector } from 'react-redux';
import {ShopAction,tipsReveal} from '@/redux/actions'
import subtract from '@/assets/images/shop/subtract.svg'
import add from '@/assets/images/shop/add.svg'
import stateLayer from '@/assets/images/shop/state-layer.svg'
import api from '@/api'
import { useTranslation } from 'react-i18next'
import IconCabbage from '@/assets/images/food/cabbage.svg'
import IconBabyChineseCabbage from '@/assets/images/food/BabyChineseCabbage.svg'
import IconCarrot from '@/assets/images/food/carrot.svg'
import IconCucumber from '@/assets/images/food/cucumber.svg'
import IconGinger from '@/assets/images/food/ginger.svg'
import IconGreenPepper from '@/assets/images/food/greenPepper.svg'
import IconHotPepper from '@/assets/images/food/hotPepper.svg'
import IconLettuce from '@/assets/images/food/lettuce.svg'
import IconOkra from '@/assets/images/food/okra.svg'
import IconPapaya from '@/assets/images/food/papaya.svg'
import IconPrpper from '@/assets/images/food/prpper.svg'
import IconWaterspout from '@/assets/images/food/waterspout.svg'
import IconwhiteRadish from '@/assets/images/food/whiteRadish.svg'
import WeightInput from '@/components/WeightInput'
const FoodDialog = (props,ref)=>{
  useNavigation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [appendOpen,setAppendOpen] = useState(false)
  const [detail,setDetail] = useState({
    product_type:'',
    product_id:'',
  })
  const [state,setState] = useState({
    jin:0,
    two:0,
  })
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setDetail(e)
      setAppendOpen(true)
    }
  }))
  const handleClose = ()=>{
    setAppendOpen(false)
  }
  const handleSubmit = async ()=>{
    if(state.jin == 0 && state.two == 0)return 
    if(state.two > 9)return setState({ ...state, ['two']: 9 })
    const num = Number(state.jin) + Number(state.two / 10);
    if(Number.isNaN(num))return 
    const {data,code,msg} = await api.shop.PostCart({
      product_id:detail.product_id,
      num:num,
    })
    if(code != 0)return dispatch(tipsReveal({message:msg,type:'error'}))
    handleClose()
    dispatch(ShopAction())
  }
  return (
    <Dialog className="food-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={appendOpen}>
      {detail.product_type == '菜心' && <img className="append-dialog_img" src={IconCabbage} alt="" />}
      {detail.product_type == '唐生菜' && <img className="append-dialog_img" src={IconLettuce} alt="" />}
      {detail.product_type== '秋葵' && <img className="append-dialog_img" src={IconCucumber} alt="" />}
      {detail.product_type == '青椒' && <img className="append-dialog_img" src={IconGreenPepper} alt="" />}
      {detail.product_type == '通菜' && <img className="append-dialog_img" src={IconWaterspout} alt="" />}
      {detail.product_type == '娃娃菜' && <img className="append-dialog_img" src={IconBabyChineseCabbage} alt="" />}
      {detail.product_type == '辣椒' && <img className="append-dialog_img" src={IconPrpper} alt="" />}
      {detail.product_type == '尖椒' && <img className="append-dialog_img" src={IconHotPepper} alt="" />}
      {detail.product_type == '木瓜' && <img className="append-dialog_img" src={IconPapaya} alt="" />}
      {detail.product_type == '青瓜' && <img className="append-dialog_img" src={IconOkra} alt="" />}
      {detail.product_type == '紅蘿蔔 ' && <img className="append-dialog_img" src={IconCarrot} alt="" />}
      {detail.product_type == '白蘿蔔' && <img className="append-dialog_img" src={IconwhiteRadish} alt="" />}
      {detail.product_type == '姜' && <img className="append-dialog_img" src={IconGinger} alt="" />}
      <div className="append-dialog_input">
        <WeightInput label="斤" name="jin" formData={state} setFormData={setState} max={detail.purchase_limit} value={state.jin} />
        {/* <WeightInput label="兩" name="two" formData={state} setFormData={setState} max={detail.purchase_limit} value={state.two} /> */}
      </div>
      <div className="append-dialog_button">
        <Button variant="contained" color="primary" onClick={()=>handleSubmit()}>
          <img src={stateLayer} alt="" />
          {t('shopping.addToCart')}
        </Button>
      </div>
    </Dialog>
  )
}
export default FoodDialog